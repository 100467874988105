import React from "react";
// import AllTogether from "./AllTogether";
// import AddressList from "./AddressList";
import Logo from "./logo";

function App() {
	return (
		<div className="App">
			<Logo />
		</div>
	);
}

export default App;
